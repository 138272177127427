<script setup>
import { ref, onMounted, watch, getCurrentInstance } from "vue";
import { useRouter, useRoute } from "vue-router";
import { ElButton, ElSelect, ElOption } from "element-plus";
import IconDropDown from "../components/IconDropDown.vue";
import { toRaw } from '@vue/reactivity'

const { proxy } = getCurrentInstance();
let route = useRoute();
let router = useRouter()

import { useI18n } from "vue-i18n";
	// 在js中可以引入vue-i18提供的hook
	const { t, tm, locale } = useI18n();

let language = ref(tm('language').current.AB);

let laList = ref(toRaw(tm('language')))

const LangChange = (value)=>{
  locale.value = value
  localStorage.setItem('lang',value)
  laList.value = toRaw(tm('language'))
}

const toPath = (path)=>{
  router.push(path)
}
const toUrl = (url)=>{
  window.open(url)
}
</script>
<template>
  <div class="nav-box">
    <div class="header">
      <div class="nav">
        <div class="logo" @click="toUrl('/')">
          <img src="@/assets/nav/logo.svg" alt="" />
        </div>
        <div class="n-list">
          <div :class="[route.path == '/' ? 'select' : '', 'item']" @click="toPath('/')">{{$t('msg.nav.home')}}</div>
          <div :class="[route.path == '/GameFi' ? 'select' : '', 'item']" @click="toPath('/GameFi')">
            {{$t('msg.nav.GameFi')}}
          </div>
          <div :class="[ 'item']" @click="toUrl('https://papers.ssrn.com/sol3/papers.cfm?abstract_id=4896145')">
            {{$t('msg.nav.Whitepaper')}}
          </div>
        </div>
      </div>
      <div class="right">
        <div class="btn" @click="toUrl('https://t.me/coincraze_labs_bot')">{{$t('msg.nav.playToAirdrop')}}</div>
        <!-- <img src="@/assets/nav/yuyan.png" alt="" /> -->
        <!-- <ElSelect
          v-model="language"
          placeholder="Select"
          size="large"
          :suffix-icon="IconDropDown"
          popper-class="popup-class"
          placement="bottom"
          :fallback-placements="['bottom']"
          @change="LangChange"
        >
          <el-option
            v-for="(item, i) in laList"
            :label="item.name"
            :value="item.AB"
          >
            <div class="option">
              <span>{{ item.name }}</span>
              <img v-if="language==item.AB" class="slect-gou" src="@/assets/nav/gou.png" alt="" />
            </div>
          </el-option>
          <template #label="{ label, value }">
            <span style="font-weight: bold; text-transform: uppercase">{{
              value
            }}</span>
          </template>
        </ElSelect> -->
      </div>
    </div>
    <div class="content">
      <router-view></router-view>
    </div>
    
  </div>
</template>
<style>
.popup-class {
  background-color: #af00fd !important;
}
.el-select-dropdown__item.hover,
.el-select-dropdown__item {
  background-color: #af00fd !important; /* 鼠标悬停时的背景颜色 */
}
.el-popper__arrow::before {
  background-color: #af00fd !important;
}
.el-popper{
  border-radius: 10px !important;
}
.el-popper.is-light{
  border: #af00fd !important;
}
.el-select-dropdown__list{
  width: 186px;
}
.el-select-dropdown__item{
  width: 100%;
  padding: 0 19px 0 32px !important;
}
</style>
<style scoped lang="scss">
.nav-box {
  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 118px;
    background-image: url("../assets/nav/top-bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 10px 24px 17px;
    box-sizing: border-box;
    .nav {
      display: flex;
      align-items: center;
      .logo {
        width: 296px;
        height: 91px;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .n-list {
        display: flex;
        align-items: center;
        margin-left: 130px;
        .item {
          font-weight: 500;
          font-size: 24px;
          color: #ffffff;
          margin-left: 88px;
          text-transform: uppercase;
          cursor: pointer;
          position: relative;
          &.select {
            color: #f6ed83;
            // &::before {
            //   position: absolute;
            //   content: "";
            //   display: block;
            //   width: 120%;
            //   height: 3px;
            //   top: -20px;
            //   left: 50%;
            //   transform: translateX(-50%);
            //   background-color: #f6ed83;
            // }
            &::after {
              position: absolute;
              content: "";
              display: block;
              width: 120%;
              height: 3px;
              bottom: -20px;
              left: 50%;
              transform: translateX(-50%);
              background-color: #f6ed83;
            }
          }
        }
      }
    }
    .right {
      display: flex;
      align-items: center;
      .btn {
        width: 291px;
        height: 72px;
        background-image: url("../assets/nav/btn-bg.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        text-align: center;
        line-height: 72px;
        font-family: PingFang SC;
        font-weight: bold;
        font-size: 24px;
        color: #f8f8f8;
        text-transform: uppercase;
        cursor: pointer;
      }
      & > img {
        width: 35px;
        height: 35px;
        margin-left: 106px;
      }

      :deep(.el-select) {
        width: 100px;
        .el-select__wrapper {
          background-color: transparent;
          box-shadow: initial;
          .el-select__placeholder {
            font-weight: bold;
            font-size: 24px;
            color: #ffffff;
          }
        }

        :deep(.el-select-dropdown) {
          background-color: #af00fd;
        }
      }
    }
  }

  .content{
    width: 100%;
    // height: calc(100vh - 118px);
    overflow-y: auto;
  }
  
}

:deep(.option) {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  span{
    font-family: PingFang SC;
    font-weight: bold;
    font-size: 18px;
    color: #FFFFFF;
  }
  .slect-gou {
    width: 19px;
    height: 14px;
  }
}
</style>
