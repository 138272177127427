import { createRouter,createWebHistory,createWebHashHistory } from 'vue-router'

const routes = [
    {
        path: '/',
        name:'home',
        component:() => import('../views/home/home.vue')
    },
    {
        path: '/GameFi',
        name:'gameFi',
        component:() => import('../views/gameFi/index.vue')
    },
    {
        path: '/Privacy',
        name:'Privacy',
        component:() => import('../views/Privacy/index.vue')
    }
]

const router = createRouter({
    history: createWebHistory(),
    // history:createWebHashHistory(),
    routes,
})

export default router;

