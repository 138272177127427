import { createI18n, useI18n } from 'vue-i18n'
//状态管理 pinia
// import { useOutsideSystemStore } from '@/stores/modules/system.js'
import zhCN from './zh.json'
import enUS from './en.json'

const language = (navigator.language || 'en').toLocaleLowerCase() // 这是获取浏览器的语言

const i18n = createI18n({
  // 是否在vue应用程序上使用vue-i18n Legacy API（传统）模式
  legacy: false,
  // 默认当前语言 localStorage.getItem('lang') || language.split('-')[0] || 'en'
  locale: 'en',
  fallbackLocale: 'en', // 设置备用语言
  // 是否为每个组件注入全局属性和函数（true 后 在template中可以直接使用$t('')）
  globalInjection: true,
  allowcomposition:true,// 允许组合式api
  // 语言合集
  messages: {
    zh: zhCN,
    en: enUS
  }
})

const locale = i18n.global.locale

export { useI18n, locale, i18n }