import { createApp } from 'vue'
import './style.css'
import App from './App.vue'
import router from './router/index'

import {i18n} from './lang/index'
import 'amfe-flexible'
import './utils/rem'
import 'element-plus/dist/index.css'


import {createPinia} from 'pinia'
// 创建 Pinia 实例
const pinia = createPinia()
const app = createApp(App)
app.use(i18n).use(pinia).use(router).mount('#app')
